/** @jsx jsx */
// eslint-disable-next-line
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Modal } from '@cimpress/react-components';
import { notificationsHubAuthorizer } from '../../../../auth';

const pStyling = css`
  p:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const AuthorizationModal = ({ returnUri }) => {
  const [closeModal, setCloseModal] = useState(false);
  const [hasError, setHasError] = useState(null);

  const authorize = () => {
    notificationsHubAuthorizer
      .ensureAuthorized({ returnUri })
      .then(response => setCloseModal(response))
      .catch(err => setHasError(err));
  };

  const onCloseModal = () => {
    setCloseModal(true);
  };

  const authErrorMsg = (
    <div css={pStyling}>
      <p>Something went wrong while authorizing for notifications.</p>
      <p>
        For support, please contact{' '}
        <a href="mailto: FinancialFlowsSupport@cimpress.com?subject=Error Authorizing Notifications Hub">
          FinancialFlowsSupport@cimpress.com
        </a>
      </p>
    </div>
  );

  const authDescription = (
    <div css={pStyling}>
      <p>
        In order to receive email notifications from conversations, you need to grant us permission
        to send you messages. This only needs to be done once.
      </p>
      <p>
        Clicking <strong>Authorize</strong> will direct you away from this page so you can agree to
        allow us to send you messages.
      </p>
    </div>
  );

  const authFooter = (
    <div>
      {hasError && (
        <button className="btn btn-default" onClick={onCloseModal}>
          Close
        </button>
      )}
      {!hasError && (
        <button className="btn btn-primary" onClick={authorize}>
          Authorize
        </button>
      )}
    </div>
  );

  return (
    <Modal
      show={!closeModal}
      bsStyle={hasError ? 'danger' : 'info'}
      style={``}
      closeButton={hasError}
      onRequestHide={onCloseModal}
      closeOnOutsideClick={hasError}
      footer={authFooter}
      title={
        <h3 className="modal-title">{hasError ? 'Authorization Error' : 'Before We Begin'}</h3>
      }
    >
      {hasError ? authErrorMsg : authDescription}
    </Modal>
  );
};

export default AuthorizationModal;
