import React, { useState } from 'react';
import { Modal, Select, TextField } from '@cimpress/react-components';
import useAccounts from '../../../hooks/useAccounts';
import useNewConversation from '../../../hooks/useNewConversation';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import flow from 'lodash/flow';
import reject from 'lodash/fp/reject';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { auth } from '../../../auth';
import { useConversations } from '../../../hooks/useConversations';

const StartConversationModal = ({ opened, closeModal }) => {
  const [mutate, { isLoading: isAddingConversation }] = useNewConversation();
  const { setSelectedConversation } = useConversations();

  const [participants, setParticipants] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const { data: accountData, isLoading: isAccountsLoading } = useAccounts();
  const accounts = accountData || [];

  const userAccount = auth.getProfile()['https://claims.cimpress.io/account'];
  const accountOptions = flow(
    reject(acc => acc.accountId === userAccount),
    sortBy(acc => acc.name),
    map(acc => ({
      value: acc.accountId,
      label: acc.name
    }))
  )(accounts);

  const addConversation = () => {
    const participatingAccounts = participants.map(p => ({ accountId: p.value }));
    // API should add the user's account but we can be explicit
    participatingAccounts.push({ accountId: userAccount });

    mutate({ subject, participants: participatingAccounts, initialMessage: message }).then(res => {
      setSelectedConversation(res.id);
      setParticipants([]);
      setSubject('');
      setMessage('');
      closeModal();
    });
  };

  const subjectTextField = (
    <TextField
      label="Subject"
      type="text"
      value={subject}
      onChange={e => setSubject(e.target.value)}
    />
  );

  const messageTextField = (
    <TextField
      inputStyle={{
        resize: 'vertical'
      }}
      label="Message"
      type="textarea"
      value={message}
      onChange={e => setMessage(e.target.value)}
    />
  );

  const toTextField = (
    <Select
      placeholder={'To'}
      options={accountOptions}
      value={participants}
      onChange={setParticipants}
      multi
      tether
    />
  );

  return (
    <Modal
      show={opened}
      onRequestHide={closeModal}
      closeOnOutsideClick
      title="Start Conversation"
      closeButton={true}
      footer={
        <button
          className="btn btn-primary"
          onClick={addConversation}
          disabled={isAddingConversation || !subject || !message || participants.length === 0}
        >
          {isAddingConversation ? <Spinner size="small" /> : 'Send'}
        </button>
      }
    >
      {!isAccountsLoading && toTextField}
      {subjectTextField}
      {messageTextField}
    </Modal>
  );
};

export default StartConversationModal;
