/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';

const Center = ({ children }) => (
  <div
    css={css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    `}
  >
    {children}
  </div>
);

export default Center;
