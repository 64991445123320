import { fetchWithAuth } from './fetchWithAuth';

const customizrEndpointUrl = process.env.REACT_APP_CUSTOMIZR_URL;
const resourceKey = process.env.REACT_APP_CUSTOMIZR_RESOURCE_KEY;

export const getUserConfig = async () => {
  return fetchWithAuth({
    endpointUrl: customizrEndpointUrl,
    route: `v1/resources/${resourceKey}/settings`
  }).catch(err => {
    if (err.response.status === 404) {
      return {};
    }
  });
};

export const updateUserConfig = async configSettings => {
  return fetchWithAuth({
    endpointUrl: customizrEndpointUrl,
    method: 'PUT',
    route: `v1/resources/${resourceKey}/settings`,
    body: configSettings
  });
};
