import { useMutation } from 'react-query';
import { createConversation } from '../services/Conversations';

const useNewConversation = () => {
  return useMutation(
    ({ subject, participants, initialMessage }) => {
      return createConversation(subject, participants, initialMessage);
    },
    {
      refetchQueries: ['conversations']
    }
  );
};

export default useNewConversation;
