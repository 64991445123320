/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import MessagesContainer from './MessagesContainer';
import { css, jsx } from '@emotion/core';
// import NewAttachments from '../../../components/attachments/NewAttachments';
import TypeMessageArea from './TypeMessageArea';
import { alloy } from '@cimpress/react-components/lib/colors';

const ConversationMessages = ({ conversationId }) => {
  return (
    <>
      <MessagesContainer conversationId={conversationId} />
      <div
        css={css`
          display: flex;
          align-items: center;
          border: 1px solid ${alloy};
          height: 55px;
        `}
      >
        {/* TODO: Enable once we need this button.
        <NewAttachments />
        */}
        <TypeMessageArea conversationId={conversationId} />
      </div>
    </>
  );
};

export default ConversationMessages;
