import { fetchWithAuth } from './fetchWithAuth';

const accountsEndpointUrl = process.env.REACT_APP_ACCOUNTS_URL;

export const getAccounts = () => {
  return fetchWithAuth({
    endpointUrl: accountsEndpointUrl,
    route: `api/v1/accounts?status=Active`
  }).then(res => res._embedded.item);
};
