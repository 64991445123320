import React from 'react';
import QuickCreateCatalogPricingTermBase from '@cimpress-technology/quick-create-catalog-pricing-term';
import { auth } from '../../../../auth';
import { useConversations } from '../../../../hooks/useConversations';
import useNewMessage from '../../../../hooks/useNewMessage';
import Loading from '../../../Loading';
import ErrorRobot from '../../../ErrorRobot';

const QuickCreateCatalogPricingTerm = ({ familyReferenceId }) => {
  const { data, isLoading, selectedConversation } = useConversations();
  const [mutate] = useNewMessage(selectedConversation);

  const pricingTermUrl = process.env.REACT_APP_PRICING_TERM_URL;

  const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';

  const conversation =
    selectedConversation && data && data.filter(d => d.id === selectedConversation)[0];

  const myAccountId = auth.getProfile()['https://claims.cimpress.io/account'];

  const otherAccounts = conversation.participants.filter(p => p.accountId !== myAccountId);

  if (!otherAccounts.length || otherAccounts.length > 1) {
    return (
      <>
        <ErrorRobot
          title="Could not determine which business to create contract for."
          msg="Make sure there is only one
          other business in this conversation."
        />
      </>
    );
  }

  const buyerAccount = otherAccounts[0];

  const sendContractAttachmentMessage = pricingTerm => {
    const { termId } = pricingTerm;
    const attachments = [
      {
        resourceUrl: `${pricingTermUrl}/api/v0/terms/${termId}/revisions/latest`,
        type: 'pricingterm'
      }
    ];
    return mutate({
      content: '',
      attachments
    });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <QuickCreateCatalogPricingTermBase
      getAccessToken={auth.getAccessToken}
      buyerAccountId={buyerAccount.accountId}
      skuFamilyId={familyReferenceId}
      isProduction={isProduction}
      onCreated={sendContractAttachmentMessage}
    />
  );
};

export default QuickCreateCatalogPricingTerm;
