import { fetchWithAuth } from './fetchWithAuth';

const endpointUrl = process.env.REACT_APP_CONVERSATIONS_URL;

export const getConversations = () => {
  return fetchWithAuth({ endpointUrl, route: `api/v0/conversations?limit=9999` }).then(
    res => res._embedded.item
  );
};

export const getMessages = conversationId => {
  return fetchWithAuth({
    endpointUrl,
    route: `api/v0/conversations/${conversationId}/messages`
  }).then(res => res._embedded.item);
};

export const getAttachments = (conversationId, messageId) => {
  return fetchWithAuth({
    endpointUrl,
    route: `api/v0/conversations/${conversationId}/messages/${messageId}/attachments`
  }).then(res => res._embedded.item);
};

export const createMessage = ({ conversationId, message, attachments = null }) => {
  return fetchWithAuth({
    endpointUrl,
    method: 'POST',
    route: `api/v0/conversations/${conversationId}/messages`,
    body: { content: message, attachments }
  });
};

export const createConversation = (subject, participants, content = null, attachments = null) => {
  const body = {
    subject,
    participants,
    initialMessage: {
      content,
      attachments
    }
  };

  return fetchWithAuth({ endpointUrl, method: 'POST', route: `api/v0/conversations`, body });
};
