/** @jsx jsx */
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { shapes } from '@cimpress/react-components';
import Loading from '../../../components/Loading';
import Center from '../../../components/Center';
import ConversationMessages from './ConversationMessages';
import { useConversations } from '../../../hooks/useConversations';
import { alloy, white } from '@cimpress/react-components/lib/colors';
import WelcomeModal from './WelcomeModal';
import useUserConfig from '../../../hooks/useUserConfig';
import { notificationsHubAuthorizer } from '../../../auth';
import AuthorizationModal from './subscription/AuthorizationModal';
import isEmpty from 'lodash/isEmpty';

const { Robot } = shapes;

const MainChatWindow = props => {
  const { data, isLoading, selectedConversation } = useConversations();
  const conversations = data || [];

  const [welcomeModalOpened, setWelcomeModalOpened] = useState(false);
  const [loadingAuthorized, setLoadingAuthorized] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const { data: userConfigData, isLoading: loadingUserConfig } = useUserConfig();

  useEffect(() => {
    setLoadingAuthorized(true);
    notificationsHubAuthorizer
      .didUserAuthorize()
      .then(response => setAuthorized(response))
      .finally(() => setLoadingAuthorized(false));
  }, []);

  useEffect(() => {
    if (userConfigData && (userConfigData.isFirstTimeVisit || isEmpty(userConfigData))) {
      setWelcomeModalOpened(true);
    }
  }, [userConfigData]);

  let inner;

  if (selectedConversation) {
    if (!isLoading && !conversations.some(c => c.id === selectedConversation)) {
      inner = (
        <Center>
          <Robot size="lg" bsStyle="warning" />
          <h4>The conversation was not found.</h4>
        </Center>
      );
    } else {
      inner = <ConversationMessages conversationId={selectedConversation} />;
    }
  } else if (isLoading) {
    inner = (
      <Center>
        <Loading />
      </Center>
    );
  } else if (conversations.length === 0) {
    inner = (
      <Center>
        <Robot bsStyle="success" alternate />
        <h4>You don't have any conversations.</h4>
      </Center>
    );
  } else {
    inner = (
      <Center>
        <h4>Please select a conversation.</h4>
      </Center>
    );
  }

  return (
    <div
      css={css`
        border: 1px solid ${alloy};
        border-left: none;
        background: ${white};
        width: 80%;
        display: flex;
        flex-direction: column;
      `}
    >
      {inner}
      {loadingUserConfig || loadingAuthorized ? null : authorized ? (
        <WelcomeModal
          opened={welcomeModalOpened}
          onCloseModal={() => setWelcomeModalOpened(false)}
        />
      ) : (
        <AuthorizationModal returnUri={window.location.href} />
      )}
    </div>
  );
};

export default MainChatWindow;
