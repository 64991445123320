import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import NotificationsHubAuthorizer from '@cimpress-technology/notifications-hub-authorizer';

export const CLIENT_ID = 'rkuPpg6u2l8T6msdcZljWI35mXuPOcdy';
const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: `/${process.env.REACT_APP_ROUTER_BASENAME || ''}`
});

const notificationsHubAuthorizer = new NotificationsHubAuthorizer({
  env: isProduction ? 'prd' : 'int',
  centralizedAuth: auth
});

export { auth, notificationsHubAuthorizer };
