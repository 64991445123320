/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { jsx, css } from '@emotion/core';
import MessageBubble from './MessageBubble';
import groupBy from 'lodash/groupBy';
import ProductFamilyAttachments from '../../../components/attachments/productFamily';
import CatalogPriceContractAttachments from '../../../components/attachments/catalogPriceContract/message';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import useMessageAttachments from '../../../hooks/useMessageAttachments';
import useUser from '../../../hooks/useUser';
import { usePalette } from 'react-palette';
import { parse } from 'query-string';
import { getDate } from '../../../utilities/timeFunctions';
import { slate } from '@cimpress/react-components/lib/colors';
import MessageText from './MessageText';

const Message = ({ message, isFromMe }) => {
  const { data: attachments } = useMessageAttachments(message.conversationId, message.id);
  const { data: user, isLoading: isUserLoading } = useUser(message.createdBy);
  const { profile: userProfile } = user || {};

  const groupedAttachments = groupBy(attachments, a => a.type);

  const attachmentsRender = Object.keys(groupedAttachments).map(type => {
    const attachments = groupedAttachments[type];
    let inner = null;
    switch (type) {
      case 'product':
        inner = <ProductFamilyAttachments attachments={attachments} />;
        break;
      case 'pricingTerm':
        inner = <CatalogPriceContractAttachments attachments={attachments} />;
        break;
      default:
        return null;
    }

    return (
      <div
        css={css`
          ${isFromMe ? 'margin: 10px 50px 0 0;' : 'margin: 10px 0 0 50px;'}
        `}
      >
        <MessageBubble isFromMe={isFromMe} userProfile={userProfile}>
          {inner}
        </MessageBubble>
      </div>
    );
  });

  const profilePicture = userProfile && userProfile.picture;
  const picture = userProfile && (
    <img
      alt={userProfile.name}
      css={css`
        width: 35px;
        height: 35px;
        border-radius: 50%;
        ${isFromMe ? 'margin-left: 15px;' : 'margin-right: 15px;'}
      `}
      src={profilePicture}
    />
  );

  const parsedUrl = parse(profilePicture);
  const { data: colors, loading: isColorLoading } = usePalette(`${parsedUrl.d || profilePicture}`);

  const flexStyles = css`
    display: flex;
    justify-content: ${isFromMe ? 'flex-end' : 'flex-start'};
  `;

  return (
    <div
      css={[
        flexStyles,
        css`
          padding: 10px 20px;
          :hover {
            background-color: #f8f8f8;
          }
        `
      ]}
    >
      <div
        css={css`
          max-width: 90%;
        `}
      >
        {userProfile && !isFromMe && !isColorLoading && (
          <div
            css={css`
              color: ${colors.vibrant};
              font-size: small;
              margin-bottom: 2px;
              text-align: left;
              margin-left: 50px;
            `}
          >
            {userProfile.name}
          </div>
        )}
        <div css={flexStyles}>
          {!isFromMe && (isUserLoading ? <Spinner size="medium" /> : picture)}
          {message.content && (
            <MessageBubble isFromMe={isFromMe}>
              <MessageText text={message.content} />
            </MessageBubble>
          )}
          {isFromMe && (isUserLoading ? <Spinner size="medium" /> : picture)}
        </div>
        {attachmentsRender}
        <div
          css={css`
            color: ${slate};
            margin-top: 2px;
            text-align: ${isFromMe ? 'right;' : 'left;'}
              ${isFromMe ? 'margin-right: 60px;' : 'margin-left: 60px;'};
          `}
        >
          {getDate(message.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default Message;
