import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import constate from 'constate';
import { useQueryParam, StringParam } from 'use-query-params';
import { getConversations } from '../services/Conversations';

const useConversationsBase = () => {
  const [selectedConversation, setSelectedConversation] = useState();
  const [qsConversationId, setQsConversationId] = useQueryParam('conversationId', StringParam);

  const conversationsQuery = useQuery('conversations', getConversations);

  useEffect(() => {
    if (!conversationsQuery.isLoading) {
      setSelectedConversation(qsConversationId);
    }
  }, [setSelectedConversation, qsConversationId, conversationsQuery.isLoading]);

  return {
    ...conversationsQuery,
    selectedConversation,
    setSelectedConversation: setQsConversationId
  };
};

export const useConversations = constate(useConversationsBase);
