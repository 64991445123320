import { fetchWithAuth } from './fetchWithAuth';

const coamEndpointUrl = process.env.REACT_APP_COAM_URL;

export const getUser = userId => {
  return fetchWithAuth({
    endpointUrl: coamEndpointUrl,
    route: `auth/access-management/v1/principals/${userId}?responseFilter=groups,permissions`
  });
};
