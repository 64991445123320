import { createMessage } from '../services/Conversations';
import { useMutation } from 'react-query';

const useNewMessage = conversationId => {
  return useMutation(
    ({ content, attachments }) => {
      return createMessage({ conversationId, message: content, attachments });
    },
    {
      refetchQueries: [['messages', { conversationId }]]
    }
  );
};

export default useNewMessage;
