/** @jsx jsx */
// eslint-disable-next-line
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import StartConversationModal from './StartConversationModal';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import { sky } from '@cimpress/react-components/lib/colors';
import { Tooltip } from '@cimpress/react-components';

const StartConversationButton = () => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <Tooltip contents="Start a Conversation">
        <IconAddCircle
          css={css`
            cursor: pointer;
            :hover {
              color: ${sky.darkest};
            }
          `}
          size="lg"
          weight="regular"
          onClick={() => setModalOpened(true)}
        />
      </Tooltip>
      {modalOpened && (
        <StartConversationModal opened={modalOpened} closeModal={() => setModalOpened(false)} />
      )}
    </>
  );
};

export default StartConversationButton;
