/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import ConversationPreviewItem from './ConversationPreviewItem';
import StartConversationButton from './StartConversationButton';
import { useConversations } from '../../../hooks/useConversations';
import orderBy from 'lodash/orderBy';
import Loading from '../../../components/Loading';
import Center from '../../../components/Center';
import { coal, alloy, shale, silver } from '@cimpress/react-components/lib/colors';

const ConversationsContainer = styled.div`
  position: relative;
  background: ${coal};
  width: 20%;
  min-width: 200px;
  max-width: 350px;
  border-right: 1px solid ${alloy};
  display: flex;
  flex-direction: column;
`;

const Conversations = () => {
  const { data, isLoading, selectedConversation, setSelectedConversation } = useConversations();
  // TODO: Sort by most recent message or created date if none?
  const conversations = orderBy(data, conv => new Date(conv.modifiedAt), ['desc']) || [];

  if (isLoading) {
    return (
      <ConversationsContainer>
        <Center>
          <Loading />
        </Center>
      </ConversationsContainer>
    );
  }

  return (
    <ConversationsContainer>
      <h4
        css={css`
          padding: 15px 15px;
          color: ${silver};
          border-bottom: 1px solid ${shale};
          margin: 0;
          display: flex;
          justify-content: space-between;
        `}
      >
        Conversations
        <StartConversationButton />
      </h4>
      <div
        css={css`
          height: 100%;
          overflow-y: auto;
        `}
      >
        {conversations.map(conv => (
          <ConversationPreviewItem
            key={conv.id}
            conversationId={conv.id}
            participants={conv.participants}
            subject={conv.subject}
            setSelectedConversation={() => setSelectedConversation(conv.id)}
            isSelected={selectedConversation === conv.id}
          />
        ))}
      </div>
    </ConversationsContainer>
  );
};

export default Conversations;
