import { CONVERSATION_RESOURCE_TYPE } from '../components/attachments/catalogPriceContract/message/constants';
import { fetchWithAuth } from './fetchWithAuth';

const endpointUrl = process.env.REACT_APP_SUBSCRIPTIONS_URL;

export const fetchAllSubscriptions = () => {
  return fetchWithAuth({
    endpointUrl,
    route: `v1/subscriptions?resourceType=${CONVERSATION_RESOURCE_TYPE}`
  });
};

export const unsubscribe = subscriptionId => {
  return fetchWithAuth({
    endpointUrl,
    method: 'DELETE',
    route: `v1/subscriptions/${subscriptionId}`
  });
};

export const subscribe = ({ filters, actionName }) => {
  const body = {
    actionName: actionName,
    resourceFilters: filters,
    resourceType: CONVERSATION_RESOURCE_TYPE,
    alertInfo: {
      channel: 'email'
    }
  };

  return fetchWithAuth({ endpointUrl, method: 'POST', route: `v1/subscriptions`, body });
};
