import React from 'react';
import CatalogPriceContractAttachment from './CatalogPriceContractAttachment';

const AttachmentMessageRender = ({ attachments }) => {
  return (
    <>
      <div>
        {attachments.map(attachment => (
          <CatalogPriceContractAttachment key={attachment.id} url={attachment.resourceUrl} />
        ))}
      </div>
    </>
  );
};

export default AttachmentMessageRender;
