/** @jsx jsx */
// eslint-disable-next-line
import React, { useEffect, useRef } from 'react';
import Message from './Message';
import { css, jsx } from '@emotion/core';
import sortBy from 'lodash/sortBy';
import { auth } from '../../../auth';
import debounce from 'lodash/debounce';
import useConversationMessages from '../../../hooks/useConversationMessages';
import get from 'lodash/get';

const chatWindowCss = css`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  & > :last-child {
    padding-bottom: 5px;
  }
`;

const MessagesContainer = ({ conversationId }) => {
  const containerRef = useRef(null);
  const messagesStartRef = useRef(null);
  const conversationIdRef = useRef(conversationId);
  const hasFetchedMore = useRef(false);

  const myUserSub = auth.getProfile()['https://claims.cimpress.io/canonical_id'];
  const { data: messagePages, fetchMore, isFetchingMore, canFetchMore } = useConversationMessages(
    conversationId
  );
  const lastPage = messagePages[messagePages.length - 1];

  const messages = messagePages.flatMap(page => get(page, '_embedded.item'));
  const sortedMessages = sortBy(messages, x => new Date(x.createdAt));
  const newLatestMessage = sortedMessages[sortedMessages.length - 1] || {};
  const { createdBy, id: newLatestMessageId } = newLatestMessage;
  const isSelf = createdBy === myUserSub;

  const latestMessageIdRef = useRef(newLatestMessageId);

  let scrollHeight;
  if (containerRef.current) {
    scrollHeight = containerRef.current.scrollHeight;
  }

  // Scroll to bottom if not everything is done rendering and the container
  // height has changed unless we've just fetched more messages
  useEffect(() => {
    if (hasFetchedMore.current) {
      return;
    }
    containerRef.current.lastChild.scrollIntoView();
  }, [scrollHeight]);

  // Scroll to bottom when a new message is posted by yourself
  useEffect(() => {
    if (newLatestMessageId !== latestMessageIdRef.current && isSelf) {
      latestMessageIdRef.current = newLatestMessageId;
      containerRef.current.lastChild.scrollIntoView();
    }
  }, [latestMessageIdRef, isSelf, newLatestMessageId]);

  // Scroll to bottom when you switch conversations
  useEffect(() => {
    if (conversationId !== conversationIdRef.current) {
      hasFetchedMore.current = false;
      conversationIdRef.current = conversationId;
      containerRef.current.lastChild.scrollIntoView();
    }
  }, [conversationId]);

  const scroll = debounce(() => {
    if (containerRef.current.scrollTop < 100 && canFetchMore && !isFetchingMore) {
      // First element is the loading indicator
      messagesStartRef.current = containerRef.current.childNodes[1];
      fetchMore(lastPage).then(() => {
        hasFetchedMore.current = true;
        messagesStartRef.current.scrollIntoView();
      });
    }
  }, 200);

  return (
    <div css={chatWindowCss} ref={containerRef} onScroll={scroll}>
      {isFetchingMore ? (
        <p css={{ textAlign: 'center', marginTop: '10px' }}>Loading history...</p>
      ) : (
        <p css={{ display: 'none' }} />
      )}
      {sortedMessages &&
        sortedMessages.map(message => (
          <Message
            key={message.id}
            id={message.id}
            message={message}
            isFromMe={myUserSub === message.createdBy}
          />
        ))}
    </div>
  );
};

export default MessagesContainer;
