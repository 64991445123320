import { useQuery } from 'react-query';
import { getAttachments } from '../services/Conversations';

const useMessageAttachments = (conversationId, messageId) => {
  return useQuery(
    ['message.attachments', { conversationId, messageId }],
    () => getAttachments(conversationId, messageId),
    {
      staleTime: 99999999
    }
  );
};

export default useMessageAttachments;
