// eslint-disable-next-line
import React, { Component } from 'react';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

export default class Loading extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <Spinner />
        </div>
      </div>
    );
  }
}
