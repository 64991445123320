import { TextField } from '@cimpress/react-components';
import React, { useState } from 'react';
import useNewMessage from '../../../hooks/useNewMessage';

const whiteSpaceRegex = /^\s*$/;
const isFalsyOrWhitespace = str => !str || whiteSpaceRegex.test(str);

const TypeMessageArea = ({ conversationId }) => {
  const [mutate, { isLoading }] = useNewMessage(conversationId);

  const [chatMessage, setChatMessage] = useState('');
  const pressEnter = e => {
    if (e.keyCode === 13 && !isLoading && !isFalsyOrWhitespace(chatMessage)) {
      mutate({ content: chatMessage });
      setChatMessage('');
    }
  };

  return (
    <TextField
      style={{ marginBottom: '0px', width: '100%', paddingLeft: '20px' }}
      inputStyle={{ border: 'none', padding: '0px' }}
      type={'text'}
      placeholder={'Message...'}
      value={chatMessage}
      onChange={e => setChatMessage(e.target.value)}
      onKeyDown={pressEnter}
      autoFocus
    />
  );
};

export default TypeMessageArea;
