/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { jsx } from '@emotion/core';
import { sky, platinum } from '@cimpress/react-components/lib/colors';

const MessageBubble = props => {
  const { children, isFromMe } = props;

  const baseBubbleCss = {
    borderRadius: '20px',
    padding: '10px',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces'
  };

  const bubbleCss = isFromMe
    ? {
        backgroundColor: `${sky.darker}`,
        borderTopRightRadius: '0px'
      }
    : {
        backgroundColor: `${platinum}`,
        borderTopLeftRadius: '0px'
      };

  return <div css={[baseBubbleCss, bubbleCss]}>{children}</div>;
};

export default MessageBubble;
