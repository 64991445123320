import { useMutation, useQuery } from 'react-query';
import { getUserConfig, updateUserConfig } from '../services/Customizr';

const useUserConfig = () => {
  const userConfigQuery = useQuery('userConfig', getUserConfig);

  const userConfigMutation = useMutation(
    configSettings => {
      return updateUserConfig(configSettings);
    },
    {
      refetchQueries: ['userConfig']
    }
  );

  return {
    ...userConfigQuery,
    userConfigMutation: userConfigMutation
  };
};

export default useUserConfig;
