import { useQuery } from 'react-query';
import { fetchWithAuth } from '../../../services/fetchWithAuth';

const useProductFamilyUrl = productFamilyUrl => {
  return useQuery(
    ['productFamily', { productFamilyUrl }],
    () => fetchWithAuth({ endpointUrl: productFamilyUrl }),
    {
      staleTime: 99999999
    }
  );
};

export default useProductFamilyUrl;
