import React, { useState } from 'react';
import ProductCard from '@cimpress-technology/product-card';
import get from 'lodash/get';
import useProductFamilyUrl from './useProductFamilyUrl';
import IconStartContract from '@cimpress-technology/react-streamline-icons/lib/IconStartContract';
import QuickCreateCatalogPricingTerm from '../../../components/attachments/catalogPriceContract/new/QuickCreateCatalogPricingTerm';
import { Button, Modal } from '@cimpress/react-components';

const ProductFamilyAttachment = ({ url, ...rest }) => {
  const [modalOpened, setModalOpened] = useState(false);

  const { data, isFetching } = useProductFamilyUrl(url);

  const { family = {} } = data || {};

  const { referenceId, scenes, name, description, tenant = {} } = family;

  const scene = get(scenes, '[0].underlaySceneUri');

  const openInMarketplace = () =>
    window.open(`https://marketplace.products.cimpress.io/family/${referenceId}`, '_blank');

  const productDetails = [
    {
      key: 'Description',
      value: description
    }
  ];

  return (
    <>
      <ProductCard
        loading={isFetching}
        size="m"
        orientation="horizontal"
        notSelectable
        productName={name}
        productFulfiller={tenant.name}
        productDetails={productDetails}
        onClick={openInMarketplace}
        allowImageClick
        imageUrl={scene}
        uniqueId={referenceId}
        {...rest}
      />
      <Button
        className={`btn btn-primary btn-block`}
        onClick={() => setModalOpened(true)}
        style={{ margin: '4px 0' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconStartContract size="lg" style={{ marginRight: '4px' }} />
          Create Contract
        </div>
      </Button>
      {modalOpened && (
        <Modal
          show={modalOpened}
          onRequestHide={() => setModalOpened(false)}
          closeOnOutsideClick
          title={'Create a Contract'}
          closeButton
        >
          <QuickCreateCatalogPricingTerm familyReferenceId={referenceId} />
        </Modal>
      )}
    </>
  );
};

export default ProductFamilyAttachment;
