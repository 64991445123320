/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { jsx } from '@emotion/core';
import { Modal } from '@cimpress/react-components';
import useUserConfig from '../../../hooks/useUserConfig';
import SubscribeToNewConversationsCheckBox from './subscription/SubscribeToNewConversationsCheckbox';

const WelcomeModal = ({ opened, onCloseModal }) => {
  const { data, userConfigMutation } = useUserConfig();

  const closeModal = async () => {
    const config = data || {};
    const [mutate] = userConfigMutation;

    config.isFirstTimeVisit = false;
    await mutate(config);
    onCloseModal();
  };

  const content = (
    <>
      <p css={{ marginBottom: '20px' }}>
        You can change this setting anytime with the gear icon in the top right.
      </p>
      <SubscribeToNewConversationsCheckBox />
    </>
  );

  const footer = (
    <button className="btn btn-primary" onClick={closeModal}>
      Close
    </button>
  );

  return (
    <Modal
      show={opened}
      onRequestHide={closeModal}
      title={'Welcome to Conversations!'}
      footer={footer}
      closeButton
      closeOnOutsideClick
    >
      {content}
    </Modal>
  );
};

export default WelcomeModal;
