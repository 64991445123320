import { useQuery } from 'react-query';
import { fetchWithAuth } from '../services/fetchWithAuth';
import get from 'lodash/get';

const useConversationMessages = (conversationId, refetchInterval = 1000, limit = 25) => {
  return useQuery(
    ['messages', { conversationId }],
    (lastPage = {}) => {
      const nextUrl = get(lastPage, '_links.next.href');
      const endpointUrl =
        nextUrl ||
        `${process.env.REACT_APP_CONVERSATIONS_URL}/api/v0/conversations/${conversationId}/messages?limit=${limit}`;
      return fetchWithAuth({ endpointUrl });
    },
    {
      refetchInterval,
      refetchOnWindowFocus: false,
      paginated: true,
      getCanFetchMore: (lastPage, allPages) => !!get(lastPage, '_links.next.href')
    }
  );
};

export default useConversationMessages;
