import moment from 'moment';
import TimeAgo from 'react-timeago';
import React from 'react';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const strings = {
  suffixAgo: 'ago',
  seconds: '< 1 min',
  minute: '%d min',
  minutes: '%d mins',
  hour: '%d hour',
  hours: '%d hours',
  day: '%d day',
  days: '%d days',
  month: '%d month',
  months: '%d months',
  year: '%d year',
  years: '%d years'
};

export const timeAgoFormatter = buildFormatter(strings);

// Format timestamp to use timeago if it's less than a day, yesterday for a day ago,
// day of the week if it's within the last week, and day + timestamp longer than that
export const getDate = messageTime => {
  const diff = moment()
    .startOf('day')
    .diff(moment(messageTime).startOf('day'), 'days');

  if (diff < 1) {
    return (
      <TimeAgo
        title={`Today at ${moment(messageTime).format('hh:mm A')}`}
        date={messageTime}
        formatter={timeAgoFormatter}
      />
    );
  }
  if (diff === 1) {
    return `Yesterday at ${moment(messageTime).format('hh:mm A')}`;
  }
  if (diff < 7) {
    return moment(messageTime).format('ddd · hh:mm A');
  }

  return moment(messageTime).format('MMM D · hh:mm A');
};
