/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import TimeAgo from 'react-timeago';
import { auth } from '../../../auth';
import useConversationMessages from '../../../hooks/useConversationMessages';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import { timeAgoFormatter } from '../../../utilities/timeFunctions';
import useAccounts from '../../../hooks/useAccounts';
import { alloy, coal, cobalt, shale, slate, silver } from '@cimpress/react-components/lib/colors';
import SubscriptionIcon from './subscription/SubscriptionIcon';

const ConversationPreviewItem = ({
  conversationId,
  participants,
  subject,
  setSelectedConversation,
  isSelected
}) => {
  const participantsButMe = participants.filter(
    p => p.accountId !== auth.getProfile()['https://claims.cimpress.io/account']
  );

  const { isLoading, getAccountNameById } = useAccounts();

  const participantNames =
    !isLoading && participantsButMe.map(p => getAccountNameById(p.accountId)).join(', ');

  const { data: messagePages } = useConversationMessages(conversationId, null);
  const messages = messagePages.flatMap(page => get(page, '_embedded.item'));
  const latestMessage = maxBy(messages, x => new Date(x.createdAt));

  const resourceUri = `${process.env.REACT_APP_CONVERSATIONS_URL}/api/v0/conversations/${conversationId}`;

  return (
    <div
      css={css`
        cursor: pointer;
        background-color: ${isSelected ? shale : coal};
        :hover {
          ${!isSelected ? `background-color: ${cobalt.base};` : ''}
        }
        border-bottom: 1px solid ${alloy};
        padding: 8px 16px;
        min-height: 0;
      `}
      onClick={setSelectedConversation}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: ${silver};
        `}
      >
        <span
          css={css`
            display: flex;
          `}
        >
          <h6
            css={css`
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {participantNames}
          </h6>
          <SubscriptionIcon
            accessToken={auth.getAccessToken()}
            resourceUri={resourceUri}
            refreshInterval={300}
          />
        </span>
        <p
          css={css`
            margin-right: 16px;
            font-weight: 500;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {subject}
        </p>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <p
            css={css`
              color: ${slate};
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {(latestMessage && latestMessage.content) || <i>No messages</i>}
          </p>
          <small
            css={css`
              margin-left: 16px;
              white-space: nowrap;
            `}
          >
            {latestMessage && (
              <TimeAgo date={latestMessage.createdAt} formatter={timeAgoFormatter} />
            )}
          </small>
        </div>
      </div>
    </div>
  );
};

export default ConversationPreviewItem;
