import { useMutation, useQuery } from 'react-query';
import { fetchAllSubscriptions, subscribe, unsubscribe } from '../services/Subscriptions';

const useSubscriptions = () => {
  const subscriptionsQuery = useQuery('subscriptions', fetchAllSubscriptions);

  const addSubscriptionMutation = useMutation(
    ({ filters, actionName }) => {
      return subscribe({ filters, actionName });
    },
    {
      refetchQueries: ['subscriptions']
    }
  );

  const deleteSubscriptionMutation = useMutation(
    subscriptionId => {
      return unsubscribe(subscriptionId);
    },
    {
      refetchQueries: ['subscriptions']
    }
  );

  return {
    ...subscriptionsQuery,
    addSubscriptionMutation,
    deleteSubscriptionMutation
  };
};

export default useSubscriptions;
