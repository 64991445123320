import { useQuery } from 'react-query';
import { fetchWithAuth } from '../../../../services/fetchWithAuth';

const usePricingTermUrl = (pricingTermUrl, reactQueryOptions) => {
  return useQuery(
    ['pricingTerm', { pricingTermUrl }],
    () => fetchWithAuth({ endpointUrl: pricingTermUrl }),
    {
      ...reactQueryOptions
    }
  );
};

export default usePricingTermUrl;
