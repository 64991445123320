import React from 'react';
import { Modal } from '@cimpress/react-components';
import { SelectedSkuBin } from '@cimpress-technology/ff-react-components/lib/SkuSelector';
import { ClassNames } from '@emotion/core';
import ProductFamilyAttachment from './ProductFamilyAttachment';
import keyBy from 'lodash/keyBy';

const ProductFamilyAttachmentViewAllModal = ({ opened, onCloseModal, attachments }) => {
  const skus = keyBy(
    attachments.map((a, i) => ({ resourceUrl: a.resourceUrl, index: i })),
    // TODO: Put it back to resourceurl.
    a => a.index
  );

  return (
    <Modal
      show={opened}
      onRequestHide={onCloseModal}
      title={`View all ${attachments.length} products`}
      closeButton
      closeOnOutsideClick
    >
      <SelectedSkuBin
        skus={skus}
        // TODO: Enable later. You can get this information from the cache.
        showCsvDownload={false}
        showFilter={false}
        customSkuCardVirtualizedListProps={{ rowHeight: 82 }}
        skuCardCustomRenderFn={({ skuInfo }) => (
          <ClassNames>
            {({ css }) => (
              <ProductFamilyAttachment
                size="s"
                url={skuInfo.resourceUrl}
                className={css`
                  width: 100% !important;
                `}
              />
            )}
          </ClassNames>
        )}
      />
    </Modal>
  );
};

export default ProductFamilyAttachmentViewAllModal;
