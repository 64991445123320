import React from 'react';
import usePricingTermUrl from './usePricingTermUrl';
import TermStatusLabel from './TermStatusLabel';
import get from 'lodash/get';
import Loading from '../../../Loading';
import { timeAgoFormatter } from '../../../../utilities/timeFunctions';
import TimeAgo from 'react-timeago';
import { Card } from '@cimpress/react-components';
import moment from 'moment';
import { TermType } from './constants';

const CatalogPriceContractAttachment = ({ url, ...rest }) => {
  const contractManagerUrl = process.env.REACT_APP_CONTRACT_MANAGER_URL;

  const { data: latestRevision, isLoading } = usePricingTermUrl(url, { refetchInterval: 5000 });

  if (!latestRevision || isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Card
        header={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              {latestRevision.type === TermType.CATALOG ? 'Catalog ' : ''}Pricing Contract
            </span>
          </div>
        }
      >
        <h5>{get(latestRevision, 'additionalData.name')}</h5>
        <p>
          Current Status: <TermStatusLabel status={latestRevision.status} />
        </p>
        <p>
          Last updated{' '}
          <em>
            <TimeAgo date={moment.utc(latestRevision.createdAt)} formatter={timeAgoFormatter} />
          </em>{' '}
          by <em>{latestRevision.createdBy}</em>
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${contractManagerUrl}/${latestRevision.termId}/${latestRevision.revisionId}`}
        >
          Go to Contract Manager
        </a>
      </Card>
    </div>
  );
};

export default CatalogPriceContractAttachment;
