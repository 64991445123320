/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import Conversations from './Conversations';
import MainChatWindow from './MainChatWindow';
import { css, jsx } from '@emotion/core';
import { useConversations } from '../../../hooks/useConversations';

const ChatPlatform = () => {
  return (
    <useConversations.Provider>
      <div
        css={css`
          display: flex;
          justify-content: center;
          max-width: 100vw;
          max-height: 80vh;
          min-height: 80vh;
        `}
      >
        <Conversations />
        <MainChatWindow />
      </div>
    </useConversations.Provider>
  );
};

export default ChatPlatform;
