import React from 'react';
import { TermStatus } from './constants';
import { Label } from '@cimpress/react-components';

const TermStatusLabel = ({ status }) => {
  switch (status) {
    case TermStatus.PENDING:
      return <Label text="Pending Approval" type="info" />;

    case TermStatus.REJECTED:
      return <Label text="Rejected" type="danger" />;

    case TermStatus.APPROVED:
      return <Label text="Approved" type="success" />;

    case TermStatus.TERMINATED:
      return <Label text="Terminated" type="danger" />;

    default:
      return <Label text={status} type="info" />;
  }
};

export default TermStatusLabel;
