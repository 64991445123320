import { useQuery } from 'react-query';
import { getUser } from '../services/Coam';

const useUser = userId => {
  return useQuery(['user', { userId }], () => getUser(userId), {
    staleTime: 99999999
  });
};

export default useUser;
