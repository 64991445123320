import { buildOptions, checkResponse } from './serviceHelpers';

export const fetchWithAuth = async ({
  endpointUrl,
  method = 'GET',
  route,
  body,
  additionalHeaders = {},
  giveSimpleResponse = true
}) => {
  const options = buildOptions(method, body, additionalHeaders);
  const fullUrl = route ? `${endpointUrl}/${route}` : endpointUrl;

  try {
    const rawResponse = await fetch(fullUrl, options);
    const parsedResponse = await checkResponse(
      rawResponse,
      `${method} ${fullUrl}`,
      giveSimpleResponse
    );
    return parsedResponse;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    throw error;
  }
};

export default fetchWithAuth;
