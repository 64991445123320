import React from 'react';

// A bit permissive regex for URLs (e.g. multiple periods match).
// Non capturing groups for the scheme, domain should be 2-6 characters, and everything after word boundary is meant for query string things
const urlRegex = /((?:(?:http|https):\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
const simpleEmailRegex = /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/;

const MessageText = ({ text }) => {
  const strings = text.split(urlRegex);

  return strings.map((str, idx) => {
    const isMatch = urlRegex.test(str);

    let link = str;
    if (simpleEmailRegex.test(str)) {
      link = `mailto:${str}`;
    } else if (!str.startsWith('http')) {
      // Hack to force absolute urls when there's no scheme for the URL
      link = `//${str}`;
    }

    return isMatch ? (
      <a key={idx} target="_blank" rel="noopener noreferrer" href={link}>
        {str}
      </a>
    ) : (
      str
    );
  });
};

export default MessageText;
