export const TermStatus = {
  DRAFT: 'draft',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  TERMINATED: 'terminated'
};

export const TermType = {
  CATALOG: 'list',
  PRIVATE: 'special'
};

export const CONVERSATION_RESOURCE_TYPE = 'conversation';
export const NEW_CONVERSATION_ACTION_TYPE = 'newConversation';
