import { useQuery } from 'react-query';
import { getAccounts } from '../services/Accounts';
import { useEffect, useState } from 'react';
import keyBy from 'lodash/keyBy';

const useAccounts = () => {
  const [accountsMap, setAccountsMap] = useState({});
  const getAccountNameById = accountId => {
    const account = accountsMap[accountId] || {};
    return account.name || 'Unknown Account';
  };

  const accountsQuery = useQuery('accounts', getAccounts, {
    staleTime: 99999999
  });

  const { data } = accountsQuery;
  useEffect(() => {
    setAccountsMap(keyBy(data, 'accountId'));
  }, [data]);

  return {
    ...accountsQuery,
    accountsMap,
    getAccountNameById
  };
};

export default useAccounts;
