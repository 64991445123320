/** @jsx jsx */
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Checkbox } from '@cimpress/react-components';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import useSubscriptions from '../../../../hooks/useSubscriptions';
import { auth } from '../../../../auth';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import { NEW_CONVERSATION_ACTION_TYPE } from '../../../../components/attachments/catalogPriceContract/message/constants';

const SubscribeToNewConversationsCheckBox = () => {
  const [checked, setChecked] = useState(false);
  const {
    data,
    isLoading,
    addSubscriptionMutation,
    deleteSubscriptionMutation
  } = useSubscriptions();
  const [subscribe, { isLoading: isSubscribing }] = addSubscriptionMutation;
  const [unsubscribe, { isLoading: isUnsubscribing }] = deleteSubscriptionMutation;
  const subscriptions = data ? data.subscriptions : [];

  const profile = auth.getProfile();
  const userAccount = profile['https://claims.cimpress.io/account'];

  const filter = {
    operator: 'contains',
    nestedPath: '/resource/participants',
    path: '/resource/participants/accountId',
    value: userAccount
  };

  const sub = find(subscriptions, sub => find(sub.resourceFilters, fil => isEqual(fil, filter)));

  useEffect(() => {
    setChecked(sub !== undefined);
  }, [sub]);

  const onChange = async () => {
    if (checked) {
      await unsubscribe(sub.id);
    } else {
      await subscribe({ filters: [filter], actionName: NEW_CONVERSATION_ACTION_TYPE });
    }

    setChecked(checked => !checked);
  };

  return isLoading ? (
    <Spinner size="small" />
  ) : (
    <Checkbox
      disabled={isSubscribing || isUnsubscribing}
      onChange={onChange}
      checked={checked}
      label="Subscribe to New Conversations"
    />
  );
};

export default SubscribeToNewConversationsCheckBox;
