import React, { useState } from 'react';
import ProductFamilyAttachment from './ProductFamilyAttachment';
import ProductFamilyAttachmentViewAllModal from './ProductFamilyAttachmentViewAllModal';
import { Button } from '@cimpress/react-components';

const MAX_PREVIEW_COUNT = 2;

const ProductFamilyAttachments = ({ attachments }) => {
  const [modalOpened, setModalOpened] = useState(null);
  return (
    <>
      <div>
        {attachments.slice(0, MAX_PREVIEW_COUNT).map(attachment => (
          <ProductFamilyAttachment key={attachment.id} url={attachment.resourceUrl} />
        ))}
      </div>
      {attachments.length > MAX_PREVIEW_COUNT && (
        <>
          <div style={{ textAlign: 'center' }}>
            <Button type="link" onClick={() => setModalOpened(true)}>
              ...and {attachments.length - MAX_PREVIEW_COUNT} more
            </Button>
          </div>
          {// even when the modal isn't "opened" the modal still get's rendered.
          // To prevent unnecessary calls from happening until the modal is open for the first time, check for null
          // which indicates the modal has never been opened..
          modalOpened !== null && (
            <ProductFamilyAttachmentViewAllModal
              opened={modalOpened}
              onCloseModal={() => setModalOpened(false)}
              attachments={attachments}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductFamilyAttachments;
