import React from 'react';
import { shapes } from '@cimpress/react-components';

const { Robot } = shapes;

const ErrorRobot = ({ title, msg, includeContact = true }) => {
  let titleRender = title;
  let msgRender = msg;

  if (typeof title === 'string') {
    titleRender = <h4>{title}</h4>;
  }

  if (typeof msg === 'string') {
    msgRender = <h5>{msg}</h5>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {titleRender}
      <Robot bsStyle="danger" size="sm" />
      {msgRender}
      {includeContact && (
        <h5>
          {`If you need assistance, please contact`}{' '}
          <a href="mailto: FinancialFlowsSupport@cimpress.com?subject=Issues with quick contract create flow">
            FinancialFlowsSupport@cimpress.com
          </a>
        </h5>
      )}
    </div>
  );
};

export default ErrorRobot;
