/** @jsx jsx */
// eslint-disable-next-line
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import SubscribeToNewConversationsCheckBox from '../pages/marketplace-inquiry/chat/subscription/SubscribeToNewConversationsCheckbox';
import { Modal } from '@cimpress/react-components';
import IconCog from '@cimpress-technology/react-streamline-icons/lib/IconCog';

// TODO: Switch back to platform settings modal when it's needed
const Settings = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const onCloseModal = () => {
    setModalOpened(false);
  };

  const content = <SubscribeToNewConversationsCheckBox />;

  const footer = (
    <button className="btn btn-primary" onClick={onCloseModal}>
      Close
    </button>
  );

  return (
    <>
      <a href="#!" css={{ cursor: 'pointer' }} onClick={() => setModalOpened(true)}>
        <IconCog />
      </a>
      {modalOpened && (
        <Modal
          footer={footer}
          show={modalOpened}
          onRequestHide={onCloseModal}
          title={'Settings'}
          closeButton
          closeOnOutsideClick
        >
          {content}
        </Modal>
      )}
    </>
  );
};

export default Settings;
