/** @jsx jsx */
// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import WatchComponent from 'react-cimpress-baywatch/lib/WatchComponent';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { Tooltip } from '@cimpress/react-components';
import { sky } from '@cimpress/react-components/lib/colors';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconAlarmBell from '@cimpress-technology/react-streamline-icons/lib/IconAlarmBell';
import IconAlarmBellOff from '@cimpress-technology/react-streamline-icons/lib/IconAlarmBellOff';

const iconCss = css`
  cursor: pointer;
  :hover {
    color: ${sky.darkest};
  }
`;

const SubscriptionIcon = ({ locale, accessToken, resourceUri, refreshInterval }) => {
  const renderOnError = () => {
    return (
      <Tooltip direction="right" contents="Unable to get Subscription Status">
        <IconAlertTriangle size="lg" weight="fill" />
      </Tooltip>
    );
  };

  const renderOnRunning = () => {
    return <Spinner size="small" />;
  };

  const renderDefault = (subscriptionIsActive, onClickHandler) => {
    const onClickEvent = e => {
      e.stopPropagation();
      onClickHandler();
    };

    const icon = subscriptionIsActive ? (
      <IconAlarmBell css={iconCss} size="lg" weight="fill" onClick={onClickEvent} />
    ) : (
      <IconAlarmBellOff css={iconCss} size="lg" weight="regular" onClick={onClickEvent} />
    );
    const tooltipMessage = subscriptionIsActive
      ? 'Unsubscribe from this Conversation'
      : 'Subscribe to this Conversation';

    return (
      <Tooltip direction="right" contents={tooltipMessage}>
        {icon}
      </Tooltip>
    );
  };

  return (
    <WatchComponent
      locale={locale}
      accessToken={accessToken}
      resourceUri={resourceUri}
      refreshInterval={refreshInterval}
      renderOnError={renderOnError}
      renderOnRunning={renderOnRunning}
      renderDefault={renderDefault}
      addClickHandler={false}
    />
  );
};

export default SubscriptionIcon;
